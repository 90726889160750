import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useContext,
  useRef,
} from "react";

import styled, { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";
import AOS from "aos";

import Header from "../Header";
import Footer from "../Footer";

import ModalVideo from "../ModalVideo";

import GlobalContext from "../../context/GlobalContext";

import GlobalStyle from "../../utils/globalStyle";

import imgFavicon from "../../assets/favicon.png";

import "../../assets/fonts/fontawesome-5/webfonts/fa-brands-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-regular-400.ttf";
import "../../assets/fonts/fontawesome-5/webfonts/fa-solid-900.ttf";
import "../../assets/fonts/typography-font/Circular-Std-Book.ttf";
import "../../assets/fonts/typography-font/CircularStd-Black.ttf";
import "../../assets/fonts/typography-font/CircularStd-BlackItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Bold.ttf";
import "../../assets/fonts/typography-font/CircularStd-BoldItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Book.ttf";
import "../../assets/fonts/typography-font/CircularStd-BookItalic.ttf";
import "../../assets/fonts/typography-font/CircularStd-Medium.ttf";
import "../../assets/fonts/typography-font/CircularStd-MediumItalic.ttf";

import "../../assets/fonts/icon-font/fonts/avasta.ttf";
import "../../assets/fonts/icon-font/css/style.css";

import "./bootstrap-custom.scss";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../../node_modules/aos/dist/aos.css";

import "../../assets/fonts/icon-font/css/style.css";
import "../../assets/fonts/typography-font/typo.css";
import "../../assets/fonts/fontawesome-5/css/all.css";

import { get, merge } from "lodash";

// the full theme object
import { theme as baseTheme } from "../../utils";

const Loader = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 9999999999;
  opacity: 1;
  visibility: visible;
  transition: all 1s ease-out 0.5s;
  &.inActive {
    opacity: 0;
    visibility: hidden;
  }
`;

// options for different color modes
const modes = { light: "light", dark: "dark" };

// merge the color mode with the base theme
// to create a new theme object
const getTheme = (mode) =>
  merge({}, baseTheme, {
    colors: get(baseTheme.colors.modes, mode, baseTheme.colors),
  });

const getTitle = (pathname) => {
    switch (pathname) {
        case '/guide-to-success':
            return 'The Ultimate Tool for Operational Success | NAPA Vertical'
        case '/implement-maintenance-best-practices':
            return 'Streamline Business Processes with The Best Maintenance Practices | NAPA Vertical'
        case '/asset-maintenance-best-practices':
            return 'Asset Productivity Enhancement | NAPA Vertical'
        case '/sustainable-facility-management':
            return 'Napa Vertical Sustainable Facility Management Software | NAPA Vertical'
        case '/facility-management-software':
            return 'Facility Management Software | NAPA Vertical'
        case '/cafm-software':
            return 'CAFM software | NAPA Vertical'
        case '/cmms-software-solutions':
            return 'CMMS Software Solutions | NAPA Vertical'
        case '/non-profit-solutions':
            return 'Non-Profit Facility Management Software Solutions | NAPA Vertical'
        case '/public-sector-solutions':
            return 'Public Sector Maintenance Management Software | NAPA Vertical'
        case '/restaurant-solutions':
            return 'Restaurant Maintenance Management Software | NAPA Vertical'
        case '/school-solutions':
            return 'Facility Management Software for Schools | NAPA Vertical'
        case '/property-management-solutions':
            return 'Property Maintenance Management Software | NAPA Vertical'
        case '/manufacturing-solutions':
            return 'Maintenance Management Software for Manufacturers | NAPA Vertical'
        case '/healthcare-solutions':
            return 'Healthcare Maintenance Management Software | NAPA Vertical'
        case '/contact/':
            return 'Get in touch | NAPA Vertical'
        case '/pricing':
            return 'Find the pricing plan that fits your organization | NAPA Vertical'
        case '/solutions/':
            return 'We have a solution for all your projects | NAPA Vertical'
        default:
            return 'Digital Facilities and Maintenance Operations | NAPA Vertical'
    }
}

const Layout = ({ children, pageContext, location }) => {
  const gContext = useContext(GlobalContext);

  const [visibleLoader, setVisibleLoader] = useState(true);

  useLayoutEffect(() => {
    AOS.init();
    setVisibleLoader(false);
  }, []);

  // Navbar style based on scroll
  const eleRef = useRef();

  useEffect(() => {
    window.addEventListener(
      "popstate",
      function(event) {
        // The popstate event is fired each time when the current history entry changes.
        gContext.closeAbout();
        gContext.closeContact();
        gContext.closeOffcanvas();
      },
      false
    );
    window.addEventListener(
      "pushState",
      function(event) {
        // The pushstate event is fired each time when the current history entry changes.
        gContext.closeAbout();
        gContext.closeContact();
        gContext.closeOffcanvas();
      },
      false
    );
  }, [gContext]);

  if (pageContext.layout === "bare") {
    return (
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <GlobalStyle />
        <Helmet>
          <title>Digital Facilities and Maintenance Operations | NAPA Vertical</title>
          <link rel="icon" type="image/png" href={imgFavicon} />
        </Helmet>
        <Loader id="loading" className={visibleLoader ? "" : "inActive"}>
          <div className="load-circle">
            <span className="one"></span>
          </div>
        </Loader>
        <div className="site-wrapper overflow-hidden" ref={eleRef}>
          {children}
        </div>

        <ModalVideo />
      </ThemeProvider>
    );
  }

  return (
    <>
      <ThemeProvider
        theme={
          gContext.themeDark ? getTheme(modes.dark) : getTheme(modes.light)
        }
      >
        <GlobalStyle />
        <Helmet>
          <title>{getTitle(location.pathname)}</title>
          <link rel="icon" type="image/png" href={imgFavicon} />
        </Helmet>
        <Loader id="loading" className={visibleLoader ? "" : "inActive"}>
          <div className="load-circle">
            <span className="one"></span>
          </div>
        </Loader>
        <div className="site-wrapper overflow-hidden" ref={eleRef}>
          <Header isDark={gContext.headerDark} />
          {children}

          <Footer isDark={gContext.footerDark} />
        </div>

        <ModalVideo />
      </ThemeProvider>
    </>
  );
};

export default Layout;
