// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-napa-js": () => import("./../../../src/pages/about-napa.js" /* webpackChunkName: "component---src-pages-about-napa-js" */),
  "component---src-pages-asset-maintenance-best-practices-js": () => import("./../../../src/pages/asset-maintenance-best-practices.js" /* webpackChunkName: "component---src-pages-asset-maintenance-best-practices-js" */),
  "component---src-pages-cafm-software-js": () => import("./../../../src/pages/cafm-software.js" /* webpackChunkName: "component---src-pages-cafm-software-js" */),
  "component---src-pages-cmms-software-solutions-js": () => import("./../../../src/pages/cmms-software-solutions.js" /* webpackChunkName: "component---src-pages-cmms-software-solutions-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-facility-management-software-js": () => import("./../../../src/pages/facility-management-software.js" /* webpackChunkName: "component---src-pages-facility-management-software-js" */),
  "component---src-pages-guide-to-success-js": () => import("./../../../src/pages/guide-to-success.js" /* webpackChunkName: "component---src-pages-guide-to-success-js" */),
  "component---src-pages-healthcare-solutions-js": () => import("./../../../src/pages/healthcare-solutions.js" /* webpackChunkName: "component---src-pages-healthcare-solutions-js" */),
  "component---src-pages-implement-maintenance-best-practices-js": () => import("./../../../src/pages/implement-maintenance-best-practices.js" /* webpackChunkName: "component---src-pages-implement-maintenance-best-practices-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-manufacturing-solutions-js": () => import("./../../../src/pages/manufacturing-solutions.js" /* webpackChunkName: "component---src-pages-manufacturing-solutions-js" */),
  "component---src-pages-non-profit-solutions-js": () => import("./../../../src/pages/non-profit-solutions.js" /* webpackChunkName: "component---src-pages-non-profit-solutions-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-property-management-solutions-js": () => import("./../../../src/pages/property-management-solutions.js" /* webpackChunkName: "component---src-pages-property-management-solutions-js" */),
  "component---src-pages-public-sector-solutions-js": () => import("./../../../src/pages/public-sector-solutions.js" /* webpackChunkName: "component---src-pages-public-sector-solutions-js" */),
  "component---src-pages-restaurant-solutions-js": () => import("./../../../src/pages/restaurant-solutions.js" /* webpackChunkName: "component---src-pages-restaurant-solutions-js" */),
  "component---src-pages-school-solutions-js": () => import("./../../../src/pages/school-solutions.js" /* webpackChunkName: "component---src-pages-school-solutions-js" */),
  "component---src-pages-solutions-js": () => import("./../../../src/pages/solutions.js" /* webpackChunkName: "component---src-pages-solutions-js" */),
  "component---src-pages-sustainable-facility-management-js": () => import("./../../../src/pages/sustainable-facility-management.js" /* webpackChunkName: "component---src-pages-sustainable-facility-management-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */)
}

