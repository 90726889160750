export const menuItems = [
  {
    name: "solutions",
    label: "Solutions",
    items: [
      { name: "manufacturing-solutions", label: "Manufacturing Solutions" },
      { name: "property-management-solutions", label: "Property Management Solutions" },
      { name: "healthcare-solutions", label: "Healthcare Solutions" },
      { name: "school-solutions", label: "School Software Solutions" },
      { name: "restaurant-solutions", label: "Restaurant Solutions" },
      { name: "public-sector-solutions", label: "Public Sector Solutions" },
      { name: "non-profit-solutions", label: "Non-Profit Solutions" },
    ],
  },
  {
    label: "Features",
    items: [
      { name: "cmms-software-solutions", label: "CMMS Software" },
      { name: "cafm-software", label: "CAFM software" },
      { name: "facility-management-software", label: "Facility Management Software" }
    ],
  },
  {
    label: "Resources",
    items: [
      { name: "sustainable-facility-management", label: "Sustainable Facility Management" },
      { name: "asset-maintenance-best-practices", label: "Asset Maintenance Best Practices" },
      { name: "implement-maintenance-best-practices", label: "The Best Maintenance Practices" },
      { name: "guide-to-success", label: "Guide to Success" }
    ],
  },
 // { name: "about-napa", label: "Company" },
  {
    name: "pricing",
    label: "Pricing",
  }
];
