import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Box } from "../Core";
import Logo from "../Logo";

const TitleStyled = styled(Title)`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.5px;
  margin-bottom: 22px;
`;

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    line-height: 2.25;
    a {
      color: ${({ theme, color }) => theme.colors[color]} !important;
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;

const CopyRightArea = styled.div`
  border-top: ${({ dark, theme }) =>
    dark ? `1px solid #2f2f31 ` : `1px solid ${theme.colors.border}`};

  padding: 15px 0;
  p {
    color: ${({ dark, theme }) =>
      dark ? theme.colors.lightShade : theme.colors.darkShade};
    font-size: 13px;
    font-weight: 300;
    letter-spacing: -0.41px;
    line-height: 38px;
    margin-bottom: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;
    a {
      color: ${({ dark, theme }) =>
        dark ? theme.colors.light : theme.colors.dark} !important;
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;
      &:visited {
        text-decoration: none;
      }
      &:hover {
        text-decoration: none;
        color: ${({ theme, color }) => theme.colors.secondary} !important;
      }
    }
  }
`;

const Footer = ({ isDark = true }) => {
  return (
    <>
      {/* <!-- Footer section --> */}
      <Box bg={isDark ? "dark" : "light"}>
        <Container>
          <Box
            css={`
              padding: 80px 0 60px;
            `}
          >
            <Row className="justify-content-center">
              <Col lg="2" md="4">
                <Logo white={isDark} />
              </Col>
              <Col lg="10" md="8" className="mt-5 mt-lg-0">
                <Row>
                  <Col xs="6" lg="4">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Industry solutions
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        <li>
                          <a href="/manufacturing-solutions" target="_blank">
                            Manufacturing solutions
                          </a>
                        </li>
                        <li>
                          <a href="/property-management-solutions" target="_blank">
                            Property Management Solutions
                          </a>
                        </li>
                        <li>
                          <a href="/healthcare-solutions" target="_blank">
                            Healthcare Solutions
                          </a>
                        </li>
                        <li>
                          <a href="/school-solutions" target="_blank">
                            School Software Solutions
                          </a>
                        </li>
                        <li>
                          <a href="/restaurant-solutions" target="_blank">
                            Restaurant Solutions
                          </a>
                        </li>
                         <li>
                          <a href="/public-sector-solutions" target="_blank">
                            Government Solutions
                          </a>
                        </li>
                        <li>
                          <a href="/non-profit-solutions" target="_blank">
                            Non-Profit Solutions
                          </a>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                  <Col xs="6" lg="4">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Features
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        <li>
                          <a href="/cmms-software-solutions" target="_blank">
                            CMMS Software
                          </a>
                        </li>
                        <li>
                          <a href="/cafm-software" target="_blank">
                            CAFM software
                          </a>
                        </li>
                        <li>
                          <a href="/facility-management-software" target="_blank">
                            Facility Management Software
                          </a>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                  <Col xs="6" lg="4">
                    <div className="mb-5 mb-lg-4">
                      <TitleStyled
                        variant="card"
                        color={isDark ? "light" : "dark"}
                      >
                        Stories
                      </TitleStyled>
                      <UlStyled color={isDark ? "lightShade" : "darkShade"}>
                        <li>
                          <a href="/sustainable-facility-management" target="_blank">
                            Sustainable Facility Management
                          </a>
                        </li>
                        <li>
                          <a href="/asset-maintenance-best-practices" target="_blank">
                            Asset Maintenance Best Practices
                          </a>
                        </li>
                        <li>
                          <a href="/implement-maintenance-best-practices" target="_blank">
                            The Best Maintenance Practices
                          </a>
                        </li>
                        <li>
                          <a href="/guide-to-success" target="_blank">
                            Guide to Success
                          </a>
                        </li>
                      </UlStyled>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Box>
          <CopyRightArea dark={isDark ? 1 : 0}>
            <Row className="align-items-center">
              <Col sm="6" className="text-sm-left text-center mb-2 mb-sm-0">
                <p>&copy; Napa Vertical, All Rights Reserved</p>
              </Col>
            </Row>
          </CopyRightArea>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
